import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/98gW0aJJUBU"
    bibleGroupSrc="https://www.docdroid.net/HOsroyu/bible-group-homework-6-7-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/17370636"
  >
    <SEO title="Making the Real Jesus Known - Colossians" />
  </Layout>
)

export default SermonPost
